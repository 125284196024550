.markdown-title,
.markdown-body {
  box-sizing: border-box;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;
  padding: 45px;
}

.markdown-title-wrapper {
  font-size: 30px;
  margin-top: 1em;
  border-bottom: 1px solid #e8ecf1;
  font-weight: 700;
}

.markdown-title {
  padding: 0 45px;
}

@media (max-width: 767px) {
  .markdown-body {
    padding: 15px;
  }
  .markdown-title {
    padding: 0 15px;
  }
}

.markdown-body h1 {
  font-size: 20pt;
  line-height: 40px;
  font-weight: 700;
}
.markdown-body h2 {
  font-size: 18pt;
  line-height: 36px;
  font-weight: 700;
}
.markdown-body h3 {
  font-size: 16pt;
  line-height: 28px;
  color: #333;
}

.markdown-body h4,
.markdown-body p {
  font-size: 12pt;
  line-height: 28px;
  color: #333;
}

.markdown-body h4 {
  font-size: 14pt;
}

.markdown-body hr {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 4px);
  color: #999;
  margin-top: 0px;
  margin-bottom: 0px;
  border: none;
  border-top: 1px solid;
}

.markdown-body strong > code {
  background-color: transparent;
  color: red;
}

.container {
  position: relative;
  max-width: 1120px;
  height: 100%;
  margin: 0 auto;
  letter-spacing: 0;
  color: #01081f;
  line-height: 1;
}

.wrapper {
  background-color: #fff;
}

.nav {
  position: relative;
  box-sizing: content-box;
  width: 100%;
  margin: 0;
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 46px;
}

.nav_logo {
  width: 108px;
  height: auto;
}

.nav_link,
.nav_btn_login {
  width: 124px;
  height: 46px;
  text-align: center;
  line-height: 46px;
  font-size: 18px;
  cursor: pointer;
}

.nav_link {
  display: inline-block;
  color: #e7eaee;
  text-decoration: none;
}

.nav_right,
.nav_list {
  display: flex;
  align-items: center;
}

.nav_btn_login {
  background-color: white;
  color: #2f5bea;
  border-radius: 5px;
  padding: 0;
  margin-left: 31px;
}

.btn_toggle {
  display: none;
}

.mobile_panel {
  display: none;
}

.mobile_panel_login {
  display: none;
}

@media screen and (max-width: 480px) {
  .container {
    max-width: unset;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav {
    margin: 0;
    height: 110pw;
    padding: 0 30pw;
    box-sizing: border-box;
  }

  .nav_logo {
    width: 214pw;
    height: 48pw;
  }

  .nav_right {
    display: none;
  }

  .btn_toggle {
    display: block;
    width: 54pw;
    height: 54pw;
    cursor: pointer;
  }

  .mobile_panel {
    display: none;
  }

  .mobile_panel__shown {
    position: absolute;
    background: white;
    display: block;
    left: 0;
    top: 108pw;
    right: 0;
    padding: 0 50pw;
    z-index: 2;
    box-shadow: 0px 4pw 5pw rgba(0, 0, 0, 0.1);
  }

  .mobile_nav_list li {
    display: block;
    height: 108pw;
    cursor: pointer;
  }

  .mobile_nav_list li:not(:last-of-type) {
    border-bottom: 4pw solid #ececec;
  }

  .mobile_nav_list li > a {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 108pw;
    font-size: 32pw;
    color: #0d0f30;
  }

  .nav_right_flex {
    display: flex;
    padding-right: 24pw;
  }

  .mobile_panel_login {
    display: block;
  }
  .mobile_panel_login .reg_btn {
    font-family: PingFangSC-Medium;
    width: 104pw;
    height: 64pw;
    line-height: 64pw;
    padding: 0;
    box-sizing: border-box;
    border-radius: 5pw;
    border: 2pw solid rgba(255, 255, 255, 1);
    font-size: 30pw;
    background-color: transparent;
    color: #fff;
    font-weight: 500;
    margin-right: 20pw;
  }
  .mobile_panel_login .login_btn {
    font-family: PingFangSC-Medium;
    width: 104pw;
    height: 64pw;
    line-height: 64pw;
    padding: 0;
    box-sizing: border-box;
    border-radius: 5pw;
    border: 2pw solid #2e5bff;
    font-size: 30pw;
    background-color: #2e5bff;
    color: #fff;
    font-weight: 500;
    margin-right: 24pw;
  }
}

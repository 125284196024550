.container {
  position: relative;
  max-width: 1120px;
  height: 100%;
  margin: 0 auto;
  letter-spacing: 0;
  color: #01081f;
  line-height: 1;
}

/* 页脚 */
.footer {
  padding: 48px;
  background: #0d0f30;
}

.footer .link,
.footer .link a,
.footer .copyright {
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #7e8085;
}

.footer .copyright {
  line-height: 40px;
}

@media screen and (max-width: 480px) {
  .container {
    max-width: unset;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }
  /* 页脚 */
  .footer {
    padding: 64pw 0;
  }

  .footer .copyright {
    width: 640pw;
    font-size: 24pw;
    line-height: 38pw;
    color: rgba(125, 128, 133, 0.6);
    text-align: center;
  }
  .space{
    display: flex;
    flex-wrap: wrap;
  }
  .footer .link,
  .footer .link a {
    width: 640pw;
    font-size: 32pw;
    line-height: 1;
    color: #7e8085;
  }
}

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.flex-row-center {
  @apply flex flex-row items-center;
}

.flex-col-center {
  @apply flex flex-col items-center;
}
